@import '../../variables';

.footer{
    position:relative;
    padding-bottom:16px;
    h4{
        font-family: $font1;
        font-size:1.25em;
        padding-top:24px; margin-bottom:8px;
    }
    h5{
        font-weight:300; font-size:0.8em;
        padding-top:24px;
    }
    text-align: center;
    color: $col2;
    .imageDiv{
        padding-top:28px;
        img{
            width:24px; height:24px;
            margin:12px;
        }
    }
}