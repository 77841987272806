.footer {
  position: relative;
  padding-bottom: 16px;
  text-align: center;
  color: #2d334e;
}
.footer h4 {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  padding-top: 24px;
  margin-bottom: 8px;
}
.footer h5 {
  font-weight: 300;
  font-size: 0.8em;
  padding-top: 24px;
}
.footer .imageDiv {
  padding-top: 28px;
}
.footer .imageDiv img {
  width: 24px;
  height: 24px;
  margin: 12px;
}/*# sourceMappingURL=FooterStyle.css.map */