@import './variables';
.font1{font-family: 'Playfair Display', serif};
.font2{font-family: 'Raleway', sans-serif;}
.font3{font-family: 'PT Serif', serif;}

.header1Size{font-size:64px};
.header2Size{font-size:48px};
.header3Size{font-size:38px};
@media (max-width:$smlBreakPoint){
    .header1Size{font-size: 56px;}
    .header2Size{font-size: 42px;}
}

@media (max-width:$mobBreakPoint){
    .header1Size{font-size: 48px;}
    .header2Size{font-size: 36px;}
}

@media (max-width:$mobSmlBreakPoint){
    .header1Size{font-size: 32px;}
    .header2Size{font-size: 28px;}
}

html{
    background:$col1;
}