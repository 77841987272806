@import '../../variables';
$minWidth : 360px;
#portfolio{
    text-align: center;
    color:$col1;
    padding-top:98px;
    max-height:700px;
    background-image: url('../../Assets/Images/portBackground.jpg');
    background-position: center;
    background-repeat: no-repeat;
    aspect-ratio: 1;
    margin:auto;
    border-radius: 50%;
    position:relative;
    #sectionCont{
        min-width: $minWidth;
        h2{
            padding-top:96px;
            font-size:2.5rem;
        }
        p{
            max-width:512px;
            padding-top:56px;
            padding-bottom:24px;
            font-size:1.2em;
            margin: 0 auto;
            font-family: $font2;
            color:$col8;
        }
       
    }
    hr{
        margin:auto;
        width:560px;
        border-width: 0px;
        border-bottom-width: 2px;
        border-color:$borderCol1;
    }
    #sectionBack{
        background-color:$col250;

        position: absolute;
        top:0px;
        height:100%;
        width:100%;
        z-index: 1;
        border-radius: 50%;
        // box-shadow: 0px -4px 12px 4px black inset;

    }


    .content{
        position:relative; z-index: 3;
    }
}
@media (max-width:$smlBreakPoint){
    #portfolio{
    background-color: $col2;

        padding-top:0px;
        border-radius: 0px;
        box-shadow: 0px 0px 80px 40px rgba(143, 74, 74, 0.37) inset;
        #sectionCont{
        background-color:$col250;

            padding-top:32px;
            width:80vw;
            margin:auto;
            h2{
                padding-top:0px;
            }
            
        }
        hr{
            min-width: $minWidth;

            background-color: $col250;

            width:80vw;
            margin:auto;
            padding-top:64px;

        }
        #sectionBack{
            // height:100%;
            display:none;
            border-radius: 0px;
        }
        .galleryContainer{
        min-width: $minWidth;
        width:80vw;
            .galleryImgContainer{
                // min-width: 286px;
                width:338px;

            }
        }
    }

}
@media (max-width:$mobSmlBreakPoint){
    #portfolio{
        #sectionCont{
            width:80vw;
            min-width:0px;
            h2{
                font-size: 2em;
            }
        }
        hr{
            min-width: 0px;
        }
        #sectionBack{
            // height:100%;
            // display:none;
            border-radius: 0px;
        }
        .galleryContainer{
        min-width: 0px;
            .galleryImgContainer{
                width:80vw;
                // min-width: 0px;
                // max-width:256px;
            }
        }
    }

}