form {
  color: #f0c2d5;
  background-color: rgba(19, 19, 19, 0.5);
  width: 25%;
}
form .formCont {
  padding: 0 24px;
}
form .formCont h4 {
  padding-top: 112px;
  padding-bottom: 36px;
  font-family: "Playfair Display", serif;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: center;
}
form .formCont h5 {
  font-size: 1.25em;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
}
form .formCont .topCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
form .formCont .topCont .emailInput {
  width: 100%;
}
form .formCont .topCont .emailInput input {
  width: 90%;
}
form .formCont .topCont .emailInput:nth-of-type(2) {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
form .formCont .topCont .emailInput:nth-of-type(2) h5 {
  align-self: flex-start;
  margin-left: 15px;
}
form .formCont #btnDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
form .formCont #btnDiv #btnDivInner {
  color: #2d334e;
  background-color: #f0c2d5;
  margin: 12px;
  padding: 9px 12px;
  text-align: center;
  border: none;
  border-radius: 4px;
  font-style: italic;
  cursor: pointer;
  transition: 0.3s;
}
form .formCont #btnDiv #btnDivInner h5 {
  font-size: 1em;
}
form .formCont #btnDiv #btnDivInner:hover {
  background-color: rgba(19, 19, 19, 0.5);
  color: #f0c2d5;
  transform: scale(1.05);
  box-shadow: 0px 0px 2px #f0c2d5;
}
form .formCont #btnDiv #btnDivInner:active {
  transform: scale(0.95);
  box-shadow: 0px 0px 2px #f0c2d5 inset;
  transition: 0.1s;
}
form .formCont .input {
  width: 100%;
}
form .formCont .emailInput {
  margin-top: 12px;
}/*# sourceMappingURL=EmailForm.css.map */