.galleryContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: auto;
}
.galleryContainer button {
  font-size: 0.88em;
  padding: 12px;
  border: 1px solid #f0c2d5;
  background-color: #2d334e;
  color: #f0c2d5;
  transition: 0.35s;
  border-radius: 8px;
  cursor: pointer;
}
.galleryContainer button:hover {
  transform: scale(1.05);
  background-color: #f0c2d5;
  color: #2d334e;
}

@media (max-width: 768px) {
  .galleryContainer {
    background-color: rgba(78, 67, 67, 0.431372549);
  }
}/*# sourceMappingURL=GalleryContainer.css.map */