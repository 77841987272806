.sectionOutter {
  margin-top: 120px;
  background-color: #2d334e;
}
.sectionOutter section {
  text-align: center;
  max-width: 960px;
  margin: auto;
  color: #dddada;
}
.sectionOutter section .profileCard {
  top: 36px;
  margin: auto;
  margin-bottom: 32px;
}
.sectionOutter section .mainHeader {
  padding: 48px 0 32px 0;
  font-family: "Playfair Display", serif;
  font-size: 4.8em;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px black;
}
.sectionOutter section .mainHeader h1 {
  color: #f0c2d5;
}
.sectionOutter section h4 {
  padding-bottom: 36px;
  font-family: "Playfair Display", serif;
  font-size: 1.2em;
}
.sectionOutter section p {
  font-weight: 400;
  line-height: 1.8;
}

@media (max-width: 992px) {
  .sectionOutter section {
    width: 90vw;
  }
}/*# sourceMappingURL=HomeStyle.css.map */