@import '../../variables';

// Desktop design first because I'm being lazy
#navOutter{
    position:fixed; top : 0; z-index: 4;
    width: 100vw;
    background-color: $col1;
    .mobile{display:none};
    .desktop{display:block};
    nav{
        margin:0 auto;
        max-width: 940px;
        #navCont{
            font-family: "Raleway",sans-serif;
            font-weight: 300;
            font-size:0.9em;
            border-top: 1px solid black;
            margin: 28px 0 14px 24px;
            padding: 16px 0 0 0;
            display:flex;
            justify-content: flex-start;
            .rightLinks{
                ul{
                    display:flex;
                    flex-direction: row;
                    padding-left:96px;
                    li{
                        padding-right: 24px;
                    }
                    

                }
                
            }
            .link{
                color:$col3;
                text-decoration: none;
                cursor:pointer;
            }
            .link:hover{
                text-decoration: underline;
            }
        }
    }
    .active{
        color:$col2 !important;
    }
}
#navPush{
    padding-bottom:97.6px;
}
@media (max-width:$smlBreakPoint){
    #navOutter{
        .navBar{
            #navCont{
                margin:4px 4px;
                .desktop{
                    display:none;
                }
                .rightLinks{
                    width:100%;
                    ul{
                        justify-content: space-around;
                        padding-left:0px;
                    }
                    .mobile{
                        display:flex;
                        flex-direction:column;
                        align-items: center;
                        cursor: pointer;

                        li{
                            padding-right: 0px ;
                        }
                        img{
                            width:24px; height: 24px;
                            filter: brightness(0.4);
                        }
                        
                    }
                    .mobile:hover{
                        img{
                            filter:brightness(0.5)
                        }
                    }
                    .active{
                        img{
                            filter:brightness(0.1)
                        }
                    }
                }
            }
        }
    }
}