@import '../../../../variables';
.info{
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-position: center;
    background:no-repeat;
    background-size:contain;
    color:$col2;
    .content{
        color:$col4;
        z-index: 4;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    h3{
        color:$col1;
        margin-left:8px;
        font-family: "Playfair Display",serif;
        text-transform: uppercase;
        padding-top: 120px;
    }
    h5{
        color:$col1;
        font-weight: 400;
    }
    .backgroundFilter{
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.541);
        width:100%;
        height:100%;
        position: absolute;
    }
    .infoContainer{
        div{
            padding-bottom:8px;
        }
    }
}
@media (max-width:$mdBreakPoint){
    .info{
        border-radius: 12px 12px 0 0;
        h3{
            margin-left: 0px ;
            padding:60px 0px;
            text-shadow: 0px 0px 1px rgb(0, 0, 0);
        }
        .infoContainer{
            display:grid;
            grid-template-columns: repeat(3,33%);
            div{
                align-items: flex-start;
            }
        }
    }
}