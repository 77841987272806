#portfolio {
  text-align: center;
  color: #f0c2d5;
  padding-top: 98px;
  max-height: 700px;
  background-image: url("../../Assets/Images/portBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 1;
  margin: auto;
  border-radius: 50%;
  position: relative;
}
#portfolio #sectionCont {
  min-width: 360px;
}
#portfolio #sectionCont h2 {
  padding-top: 96px;
  font-size: 2.5rem;
}
#portfolio #sectionCont p {
  max-width: 512px;
  padding-top: 56px;
  padding-bottom: 24px;
  font-size: 1.2em;
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  color: #dddada;
}
#portfolio hr {
  margin: auto;
  width: 560px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #86b9a1;
}
#portfolio #sectionBack {
  background-color: rgba(78, 67, 67, 0.431372549);
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
}
#portfolio .content {
  position: relative;
  z-index: 3;
}

@media (max-width: 768px) {
  #portfolio {
    background-color: #2d334e;
    padding-top: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 80px 40px rgba(143, 74, 74, 0.37) inset;
  }
  #portfolio #sectionCont {
    background-color: rgba(78, 67, 67, 0.431372549);
    padding-top: 32px;
    width: 80vw;
    margin: auto;
  }
  #portfolio #sectionCont h2 {
    padding-top: 0px;
  }
  #portfolio hr {
    min-width: 360px;
    background-color: rgba(78, 67, 67, 0.431372549);
    width: 80vw;
    margin: auto;
    padding-top: 64px;
  }
  #portfolio #sectionBack {
    display: none;
    border-radius: 0px;
  }
  #portfolio .galleryContainer {
    min-width: 360px;
    width: 80vw;
  }
  #portfolio .galleryContainer .galleryImgContainer {
    width: 338px;
  }
}
@media (max-width: 356px) {
  #portfolio #sectionCont {
    width: 80vw;
    min-width: 0px;
  }
  #portfolio #sectionCont h2 {
    font-size: 2em;
  }
  #portfolio hr {
    min-width: 0px;
  }
  #portfolio #sectionBack {
    border-radius: 0px;
  }
  #portfolio .galleryContainer {
    min-width: 0px;
  }
  #portfolio .galleryContainer .galleryImgContainer {
    width: 80vw;
  }
}/*# sourceMappingURL=PortfolioStyle.css.map */