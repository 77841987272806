#contactForm {
  height: 760px;
  display: grid;
  grid-template-columns: 320px 400px 320px;
  justify-content: center;
  padding-top: 64px;
}
#contactForm .infoElement {
  height: 100%;
}
#contactForm .formElement {
  height: 100%;
  width: 100%;
}
#contactForm .mapContainer {
  height: 100%;
}

@media (max-width: 1200px) {
  #contactForm {
    grid-template-columns: 25% 400px 25%;
  }
}
@media (max-width: 992px) {
  #contactForm {
    overflow-wrap: break-word;
    width: 80vw;
    min-width: 492px;
    margin-inline: auto;
    padding-bottom: 32px;
    height: -moz-max-content;
    height: max-content;
    grid-template-columns: 90%;
    grid-template-rows: 320px 640px 320px;
  }
  #contactForm .infoElement {
    text-align: center;
  }
}
@media (max-width: 768px) {
  #contactForm {
    min-width: 100vw;
    grid-template-columns: 95%;
  }
}
@media (max-width: 576px) {
  #contactForm {
    grid-template-columns: 98%;
  }
}/*# sourceMappingURL=ContactForm.css.map */