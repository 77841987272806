.profileCard {
  position: relative;
  border-radius: 50%;
  width: 128px;
  aspect-ratio: 1;
}
.profileCard .caption {
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
}
.profileCard img {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  display: block;
}/*# sourceMappingURL=ProfileCardStyle.css.map */