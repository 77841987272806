.serviceCard {
  background-color: #f0c2d5;
  color: #222;
  min-height: 360px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 2px 2px 4px black;
}
.serviceCard .container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.serviceCard .contentCont {
  border-radius: inherit;
  width: 66%;
  padding: 16px 24px;
  justify-content: center;
}
.serviceCard .contentCont h3 {
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  font-size: 1.75em;
}
.serviceCard .contentCont h5 {
  font-size: 1.25rem;
  color: #f0c2d5;
  font-family: "PT Serif", serif;
  font-weight: 500;
  padding: 24px 0;
}
.serviceCard .contentCont p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-weight: 100;
  line-height: 24px;
  padding: 8px;
}
.serviceCard .imageCont {
  width: 33%;
  min-height: 360px;
  justify-content: center;
}
.serviceCard .imageCont .innerContainer {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  max-height: 186px;
  border-radius: 50%;
}
.serviceCard .imageCont .innerContainer .imageOverlay {
  background: rgb(242, 215, 215);
  background: radial-gradient(circle, rgba(242, 215, 215, 0) 18%, rgba(242, 215, 215, 0.61) 100%);
  box-shadow: 1px 2px 12px 0.5px rgba(248, 167, 161, 0.603);
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}
.serviceCard .imageCont .innerContainer .imageInner {
  width: 186px;
  height: 186px;
  margin: auto;
  border-radius: inherit;
}

@media (max-width: 1400px) {
  .serviceCard .imageCont {
    width: 40%;
  }
}
@media (max-width: 576px) {
  .serviceCard {
    background-position: center;
    background-size: cover;
  }
  .serviceCard .contentCont {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px -4px 2px 2px inset black;
    background-color: rgba(190, 167, 167, 0.5215686275);
    width: 100%;
  }
  .serviceCard .contentCont h5 {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
  }
  .serviceCard .contentCont p {
    font-size: 1.15em;
    font-weight: 300;
  }
  .serviceCard .imageCont {
    display: none;
  }
}/*# sourceMappingURL=ServiceCardStyle.css.map */