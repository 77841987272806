@import '../../variables';
.sectionOutter{
    // height:120px;
    margin-top:120px;
    background-color: $col2;
    section{
        text-align: center;
        max-width:960px;
        margin:auto;
        color:$col8 ;
        .profileCard{
            top:36px;
            margin:auto;
            margin-bottom:32px;
        }
        .mainHeader{
            padding: 48px 0 32px 0;
            font-family: "Playfair Display",serif;
            font-size:4.8em;
            text-transform: uppercase;
            text-shadow: 2px 2px 2px black;
            h1{
                color: $col1;
            }
        }
        h4{
            padding-bottom:36px;
            font-family: "Playfair Display",serif;
            font-size:1.2em;
        }
        p{
            font-weight: 400;
            line-height: 1.8;
        }
    }
}
@media (max-width:$mdBreakPoint){
    .sectionOutter{
        section{
            width:90vw;
        }
    }
}
