// Variables duh
$col1 : #f0c2d5; // Main text colour
$col2 : #2d334e; $col22:#4e2d31;// Main background colour, 22 is for linears
$col250 : #4e43436e;
$col3: rgba(19, 19, 19, 0.5); // Mostly used on form's background color, depending on the color class it has
$col4: rgb(179, 179, 179); 
$col5: rgb(255, 255, 255);
$col6: #222;
$col7: #606060;
$col8: #dddada;

$inpCol1: #111; // Low contrast
$inpCol2: rgb(155, 155, 155); // High contrast
$inpCheckCol1 : red;
$inpCheckCol2 : rgb(61, 8, 8);

$borderCol1 : #86b9a1; // Used for portfolio divider

$font1 : 'Playfair Display', serif;
$font2 : 'Raleway', sans-serif;
$font3 : 'PT Serif', serif;

$mobSmlBreakPoint   : 356px;
$mobBreakPoint      : 576px;
$smlBreakPoint      : 768px;
$mdBreakPoint       : 992px;
$lgBreakPoint       : 1200px;
$xxlBreakPoint      : 1400px;