@import '../../../variables';
.galleryOpenImage{
    position:fixed;
    height:100vh;
    width:100vw;
    z-index:5;
    top:0px;
    left:0px;
    .behindContent{
        top:0px;
        left:0px;
        width:100vw;
        position:absolute;
        background-color: rgba(0, 0, 0, 0.6);
        height:100vh;
    }
    .galleryContent{
        height:273px;
        bottom:0px;
        width:100vw;
        // border:1px solid black;
        position: fixed;
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        .imageContainer{
            animation-duration: 1s;
            position:absolute;
            bottom:0px;
            height:min-content;
            display:flex;
            justify-content: center;
            align-items: center;
            width:100vw;
            img{
                box-shadow:0px -2px 12px 4px #0000003b;
                display:block;
                position:relative;
                bottom:0px;
                margin:auto;
                width:100vw; max-width:320px;
                height: 273px;
            }
        }
        
        .textContainer{
            position: relative;
            z-index: 1;
            background-color: #725C585a;

            bottom:0px;
            height:80px;
            display:flex;
            flex-direction: column;
            justify-content: space-evenly;
            h4{
                font-family:$font2;
                text-transform: uppercase;
                font-weight: 400;
                font-size:24px;
            }
            h5{
                font-size:16px;
                font-weight:400;
            }
            .arrowsContainer{
                width:100vw;
                height:100%;
                position: absolute;
                display:flex;
                padding-inline: 16px;
                justify-content: space-between;
                align-items: center;
                bottom:0px;
                span{
                    // border:1px solid black;
                    width:24px; aspect-ratio: 1;
                    img{
                        display:inline;
                        width:100%;
                        height:100%;
                    }
                }
                .arrowLeft{
                    img{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .moveFromDown{
        animation-name: moveFromDown;
        animation-duration: 1s;
    }
    .close{
        animation-name: moveToDown;
        animation-duration: 1s;
    }
    .moveToLeft{
        animation-name: moveToLeft;
        animation-duration: 1s;
    }
    .moveToRight{
        animation-name: moveToRight;
        animation-duration: 1s;
    }
    .moveFromLeft{
        animation-name: moveFromLeft;
        animation-duration: 1s;
    }
    .moveFromRight{
        animation-name: moveFromRight;
        animation-duration: 1s;
    }

}
.galleryOpenImage[class~="hidden"]{
    display:none;

}
@keyframes moveFromDown{
    from {bottom:-273px;}
    to {bottom:0px}
}
@keyframes moveToDown{
    from {bottom:0px}
    to {bottom:-273px;}
}
@keyframes moveToLeft{
    from {left:0px}
    to {left:-100vw}
}
@keyframes moveToRight{
    from {left:0px}
    to {left:100vw}
}
@keyframes moveFromLeft{
    from {left:-100vw}
    to {left:0px}
}
@keyframes moveFromRight{
    from {left:100vw}
    to {left:0px}
}