.infoSection {
  padding-left: 8px;
  margin: 8px 0;
}
.infoSection .top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.infoSection .top span {
  display: block;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoSection .top span img {
  width: 12px;
  height: 12px;
  color: black;
  filter: brightness(0.25);
}
.infoSection .top h5 {
  font-size: 1.5rem;
  padding-left: 8px;
}
.infoSection li {
  padding-right: 8px;
}

@media (max-width: 992px) {
  .infoSection .top {
    justify-content: center;
  }
  .infoSection .top span {
    display: none;
  }
}/*# sourceMappingURL=InfoSectionStyle.css.map */