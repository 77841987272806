.emailInput h5 {
  margin-bottom: 4px;
}
.emailInput .input {
  padding: 4px;
  font-size: 1em;
  background-color: rgba(19, 19, 19, 0.5);
  color: #f0c2d5;
  border: 1px solid #111;
  border-bottom-color: rgb(155, 155, 155);
}
.emailInput .input:hover {
  border-color: rgb(155, 155, 155);
}
.emailInput .input:focus-visible {
  border-color: rgb(155, 155, 155);
  box-shadow: 0px 0px 6px rgb(155, 155, 155);
  outline: 0px solid black;
}

.inputCheck h5 {
  color: red;
}
.inputCheck .input {
  color: red;
}/*# sourceMappingURL=EmailInputStyle.css.map */