@import '../../../../variables';
form{
    color:$col1; background-color: $col3;
    width:25%;
    
    .formCont{
        padding: 0 24px ;
        h4{
            padding-top:112px; padding-bottom: 36px;
            font-family: "Playfair Display",serif;
            font-weight: 100;
            text-transform: uppercase;
            font-size: 2.5rem;
            text-align: center;
        }
        h5{
            font-size: 1.25em;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-weight: 400;
        }
        .topCont{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .emailInput{
                width:100%;
                input{
                    width:90%;
                }
            }
            .emailInput:nth-of-type(2){
                display:flex;
                align-items: flex-end;
                flex-direction: column;
                h5{
                    align-self: flex-start;
                    margin-left:15px;
                }
            }
            

        }
        #btnDiv{
            width:100%;
            display:flex; justify-content: center;
            padding-top:32px;
            #btnDivInner{
                color:$col2; background-color: $col1;
                margin:12px;padding:9px 12px;
                text-align: center;
                border: none; border-radius: 4px;
                font-style:italic;
                cursor: pointer;
                transition: 0.3s;
                h5{
                    font-size: 1em;
                }
            }
            #btnDivInner:hover{
                background-color: rgba(19,19,19,0.5);
                color:$col1;
                transform:scale(1.05);
                box-shadow: 0px 0px 2px $col1;
            }
            #btnDivInner:active{
                transform:scale(0.95);
                box-shadow: 0px 0px 2px $col1 inset;
                transition: 0.1s;
            }
        }
        .input{
            width:100%;
        }
        .emailInput{
            margin-top:12px;
        }
    }
}