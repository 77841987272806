@import '../../../variables';
.galleryContainer{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    // width:80vw;
    margin:auto;

        button{
            font-size:0.88em;
            padding:12px;
            border:1px solid $col1;
            background-color:$col2; color:$col1;
            transition: 0.35s;
            border-radius: 8px;
            cursor: pointer;
        }
        button:hover{
            transform: scale(1.05);
            background-color:$col1; color:$col2;
        }
}
@media (max-width:$smlBreakPoint){
    .galleryContainer{
        background-color: $col250;
    }
}