@import '../../variables';
#bookingCont{
    #booking{
        margin-top:192px;
        margin-bottom:96px;
        display:grid;
        grid-template-columns: auto auto;
        justify-content: space-around;
        div{
            width:100%;
        }
        .leftSection{
            text-align: center;
            // height:356px;
            height:max-content;
            display:flex; flex-direction: column;
            justify-content: space-evenly;
            h4{
                text-transform: uppercase;
                font-family: $font1;
                font-size: 2.5em;
                font-weight:100;
                color:$col2;
                padding-top:36px;
                padding-bottom:36px;
            }
            p{
                font-weight:100; font-size:0.95em;
                padding-bottom:24px;
            }
        }
        .formElement{
            margin:auto;
            width:100%;
            background-color: #0000;
            color:$col2;
            .input{
                background-color: #0000;
                color:$col2;
                border-left-color: #0000;
                border-top-color: #0000;
                border-right-color: #0000;
            }
            .input:focus-visible{
                border: 1px solid black;
                box-shadow: 0px #0000;
            }
            #btnDivInner{
                width:70px;
            }
        }
    }
    .mapContainer{
        margin:0px auto 64px auto;
        width:80%;
        height:320px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
    }
}
@media (max-width:$mdBreakPoint){
    #bookingCont{
        #booking{
        grid-template-columns: auto;
        row-gap: 96px;
        margin-top:32px;
        }
    }
}