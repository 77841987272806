@import '../../../../_variables.scss';

.galleryImgContainer{
    width:80vw;
    max-width:338px;
    height:64px;
    margin:16px auto;
    display: grid;
    grid-template-columns: 64px auto;
    border-radius: 20px 6px 24px 20px;
    border-top:1px solid $col7;
    z-index:2;
    .imgContainer{
        border-radius: inherit;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        img{
            border-radius: inherit;
            display:block;
            width:100%;
            height:100%;
        }
    }
    .textContainer{
        border-radius: inherit;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height:100%;
        background-color: $col8;
        display:flex; align-items: center;
        h5{
            padding-left:12px;
            text-align: left;
            color: $col7;
            font-family: $font3;
            font-size: 1.2em;
            font-weight: 500;
        }
    }
}