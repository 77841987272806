@import '../../../../variables';
.emailInput{
    h5{
        margin-bottom:4px;
    }
    .input{
        padding:4px;
        font-size:1em;
        background-color: $col3;
        color:$col1;
        border: 1px solid $inpCol1;
        border-bottom-color: $inpCol2;
    }
    .input:hover{
        border-color: $inpCol2;
    }
    .input:focus-visible{
        border-color: $inpCol2;
        box-shadow: 0px 0px 6px $inpCol2 ;
        outline:0px solid black;
    }
}
.inputCheck{
    h5{
        color:$inpCheckCol1;
    }
    .input{
        color:$inpCheckCol1;
    }
}