.galleryOpenImage {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  top: 0px;
  left: 0px;
}
.galleryOpenImage .behindContent {
  top: 0px;
  left: 0px;
  width: 100vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
}
.galleryOpenImage .galleryContent {
  height: 273px;
  bottom: 0px;
  width: 100vw;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.galleryOpenImage .galleryContent .imageContainer {
  animation-duration: 1s;
  position: absolute;
  bottom: 0px;
  height: -moz-min-content;
  height: min-content;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.galleryOpenImage .galleryContent .imageContainer img {
  box-shadow: 0px -2px 12px 4px rgba(0, 0, 0, 0.231372549);
  display: block;
  position: relative;
  bottom: 0px;
  margin: auto;
  width: 100vw;
  max-width: 320px;
  height: 273px;
}
.galleryOpenImage .galleryContent .textContainer {
  position: relative;
  z-index: 1;
  background-color: rgba(114, 92, 88, 0.3529411765);
  bottom: 0px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.galleryOpenImage .galleryContent .textContainer h4 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
}
.galleryOpenImage .galleryContent .textContainer h5 {
  font-size: 16px;
  font-weight: 400;
}
.galleryOpenImage .galleryContent .textContainer .arrowsContainer {
  width: 100vw;
  height: 100%;
  position: absolute;
  display: flex;
  padding-inline: 16px;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
}
.galleryOpenImage .galleryContent .textContainer .arrowsContainer span {
  width: 24px;
  aspect-ratio: 1;
}
.galleryOpenImage .galleryContent .textContainer .arrowsContainer span img {
  display: inline;
  width: 100%;
  height: 100%;
}
.galleryOpenImage .galleryContent .textContainer .arrowsContainer .arrowLeft img {
  transform: rotate(180deg);
}
.galleryOpenImage .moveFromDown {
  animation-name: moveFromDown;
  animation-duration: 1s;
}
.galleryOpenImage .close {
  animation-name: moveToDown;
  animation-duration: 1s;
}
.galleryOpenImage .moveToLeft {
  animation-name: moveToLeft;
  animation-duration: 1s;
}
.galleryOpenImage .moveToRight {
  animation-name: moveToRight;
  animation-duration: 1s;
}
.galleryOpenImage .moveFromLeft {
  animation-name: moveFromLeft;
  animation-duration: 1s;
}
.galleryOpenImage .moveFromRight {
  animation-name: moveFromRight;
  animation-duration: 1s;
}

.galleryOpenImage[class~=hidden] {
  display: none;
}

@keyframes moveFromDown {
  from {
    bottom: -273px;
  }
  to {
    bottom: 0px;
  }
}
@keyframes moveToDown {
  from {
    bottom: 0px;
  }
  to {
    bottom: -273px;
  }
}
@keyframes moveToLeft {
  from {
    left: 0px;
  }
  to {
    left: -100vw;
  }
}
@keyframes moveToRight {
  from {
    left: 0px;
  }
  to {
    left: 100vw;
  }
}
@keyframes moveFromLeft {
  from {
    left: -100vw;
  }
  to {
    left: 0px;
  }
}
@keyframes moveFromRight {
  from {
    left: 100vw;
  }
  to {
    left: 0px;
  }
}/*# sourceMappingURL=GalleryOpenImageStyle.css.map */