@import '../../../variables';
#contactForm{
    height:760px;
    display:grid;
    grid-template-columns: 320px 400px 320px;

    justify-content: center;
    padding-top:64px;
    .infoElement{
        height: 100%;
    }
    .formElement{
        height: 100%;
        width:100%;
    }
    .mapContainer{
        height:100%;
    }
}
@media (max-width:$lgBreakPoint){
    #contactForm{
        // height:max-content;
        grid-template-columns: 25% 400px 25%;
    }
}
@media (max-width:$mdBreakPoint){
    #contactForm{
        overflow-wrap: break-word;
        width:80vw; min-width:492px;
        margin-inline: auto;
        padding-bottom:32px;
        height:max-content;
        grid-template-columns: 90%;
        grid-template-rows:320px 640px 320px;
        .infoElement{
            text-align: center;
        }
    }
}
@media (max-width:$smlBreakPoint){
    #contactForm{
        min-width:100vw;
        grid-template-columns: 95%;
    }
}
@media (max-width:$mobBreakPoint){
    #contactForm{
        grid-template-columns: 98%;
    }
}

