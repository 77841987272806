#bookingCont #booking {
  margin-top: 192px;
  margin-bottom: 96px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-around;
}
#bookingCont #booking div {
  width: 100%;
}
#bookingCont #booking .leftSection {
  text-align: center;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#bookingCont #booking .leftSection h4 {
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  font-size: 2.5em;
  font-weight: 100;
  color: #2d334e;
  padding-top: 36px;
  padding-bottom: 36px;
}
#bookingCont #booking .leftSection p {
  font-weight: 100;
  font-size: 0.95em;
  padding-bottom: 24px;
}
#bookingCont #booking .formElement {
  margin: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #2d334e;
}
#bookingCont #booking .formElement .input {
  background-color: rgba(0, 0, 0, 0);
  color: #2d334e;
  border-left-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
}
#bookingCont #booking .formElement .input:focus-visible {
  border: 1px solid black;
  box-shadow: 0px rgba(0, 0, 0, 0);
}
#bookingCont #booking .formElement #btnDivInner {
  width: 70px;
}
#bookingCont .mapContainer {
  margin: 0px auto 64px auto;
  width: 80%;
  height: 320px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 992px) {
  #bookingCont #booking {
    grid-template-columns: auto;
    row-gap: 96px;
    margin-top: 32px;
  }
}/*# sourceMappingURL=BookingStyle.css.map */