@import '../../../variables';
.serviceCard{
    background-color: $col1;
    color:$col6;
    min-height:360px;
    // min-width: 770px;
    display:flex;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 2px 2px 4px black;
    .container{
        display:flex; flex-direction: column;
        height:100%;
    }
    .contentCont{
        border-radius: inherit;

        width:66%;
        padding: 16px 24px;
        justify-content: center;
        h3{
            font-family: "Playfair Display",serif;
            text-transform: uppercase;
            font-size: 1.75em;
        }
        h5{
            font-size: 1.25rem;
            color: $col1;
            font-family: "PT Serif", serif; font-weight: 500;
            padding: 24px 0;
        }
        p{
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; font-weight:100;
            line-height: 24px;
            padding: 8px;
        }
    }
    .imageCont{
        width: 33%;
        min-height: 360px;
        justify-content: center;
        .innerContainer{
            position:relative;
            width:max-content;
            height:max-content;
            max-height:186px;
            border-radius: 50%;
            .imageOverlay{
                background: rgb(242,215,215);
                background: radial-gradient(circle, rgba(242,215,215,0) 18%, rgba(242, 215, 215, 0.61) 100%); 
                box-shadow: 1px 2px 12px 0.5px rgba(248, 167, 161, 0.603);
                position:absolute;
                height:100%;
                width:100%;
                border-radius: inherit;
            }
            .imageInner{
                width: 186px;
                height:186px;
                margin:auto;
                border-radius: inherit;
            }
        }

    }
}
@media (max-width : $xxlBreakPoint) {
    .serviceCard{
        .imageCont{
            width: 40%;
        }
    }
}
@media (max-width : $mobBreakPoint) {
    .serviceCard{
        background-position: center;
        background-size: cover;
        .contentCont{
            border-top: 2px solid rgba(255, 255, 255, 0.1);
            box-shadow: 0px -4px 2px 2px inset black;
            background-color: #bea7a785;
            width:100%;
            h5{
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
            }
            p{
                font-size: 1.15em;
                font-weight:300;
            }
        }
        .imageCont{
            display:none;
        }
    }
}

