@import '../../../../variables';
.infoSection{
    padding-left: 8px;
    margin: 8px 0;
    .top{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        span{
            display:block;
            width:24px;
            height:24px;
            background-color: white;
            border-radius: 50%;
            display:flex;
            justify-content: center; align-items: center;
            img{
                width:12px; height: 12px;
                color:black;
                filter: brightness(0.25);
            }
        }
        h5{
            font-size:1.5rem;
            padding-left: 8px;
        }

    }
    li{
        padding-right:8px;
    }    
}
@media (max-width:$mdBreakPoint){
.infoSection{
    .top{
        justify-content: center;
        span{
            display:none;
        }
    }
}
}