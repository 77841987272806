.services {
  color: #f0c2d5;
  background-image: linear-gradient(172deg, #2d334e 0%, #4e2d31 50%);
  text-align: center;
}
.services section h2 {
  padding-top: 120px;
}
.services section h5 {
  color: #dddada;
  padding: 38px 0 134px 0;
  font-size: 0.93em;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
}
.services article {
  width: 90vw;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 45%);
  grid-gap: 36px 36px;
  justify-content: center;
  padding: 12px 0 96px 0;
}
.services hr {
  position: relative;
  bottom: 32px;
  width: 60%;
  margin: auto;
  color: rgb(179, 179, 179);
}

@media (max-width: 1400px) {
  .services article {
    width: 95vw;
    grid-template-columns: repeat(2, 48%);
  }
}
@media (max-width: 1200px) {
  .services article {
    width: 100vw;
    justify-content: space-around;
    grid-template-columns: repeat(1, 90%);
    grid-gap: 36px 4px;
  }
  .services section h5 {
    margin-inline: 4px;
  }
}/*# sourceMappingURL=ServicesStyle.css.map */