.info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-position: center;
  background: no-repeat;
  background-size: contain;
  color: #2d334e;
}
.info .content {
  color: rgb(179, 179, 179);
  z-index: 4;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.info h3 {
  color: #f0c2d5;
  margin-left: 8px;
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  padding-top: 120px;
}
.info h5 {
  color: #f0c2d5;
  font-weight: 400;
}
.info .backgroundFilter {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.541);
  width: 100%;
  height: 100%;
  position: absolute;
}
.info .infoContainer div {
  padding-bottom: 8px;
}

@media (max-width: 992px) {
  .info {
    border-radius: 12px 12px 0 0;
  }
  .info h3 {
    margin-left: 0px;
    padding: 60px 0px;
    text-shadow: 0px 0px 1px rgb(0, 0, 0);
  }
  .info .infoContainer {
    display: grid;
    grid-template-columns: repeat(3, 33%);
  }
  .info .infoContainer div {
    align-items: flex-start;
  }
}/*# sourceMappingURL=InfoStyle.css.map */