#navOutter {
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100vw;
  background-color: #f0c2d5;
}
#navOutter .mobile {
  display: none;
}
#navOutter .desktop {
  display: block;
}
#navOutter nav {
  margin: 0 auto;
  max-width: 940px;
}
#navOutter nav #navCont {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 0.9em;
  border-top: 1px solid black;
  margin: 28px 0 14px 24px;
  padding: 16px 0 0 0;
  display: flex;
  justify-content: flex-start;
}
#navOutter nav #navCont .rightLinks ul {
  display: flex;
  flex-direction: row;
  padding-left: 96px;
}
#navOutter nav #navCont .rightLinks ul li {
  padding-right: 24px;
}
#navOutter nav #navCont .link {
  color: rgba(19, 19, 19, 0.5);
  text-decoration: none;
  cursor: pointer;
}
#navOutter nav #navCont .link:hover {
  text-decoration: underline;
}
#navOutter .active {
  color: #2d334e !important;
}

#navPush {
  padding-bottom: 97.6px;
}

@media (max-width: 768px) {
  #navOutter .navBar #navCont {
    margin: 4px 4px;
  }
  #navOutter .navBar #navCont .desktop {
    display: none;
  }
  #navOutter .navBar #navCont .rightLinks {
    width: 100%;
  }
  #navOutter .navBar #navCont .rightLinks ul {
    justify-content: space-around;
    padding-left: 0px;
  }
  #navOutter .navBar #navCont .rightLinks .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  #navOutter .navBar #navCont .rightLinks .mobile li {
    padding-right: 0px;
  }
  #navOutter .navBar #navCont .rightLinks .mobile img {
    width: 24px;
    height: 24px;
    filter: brightness(0.4);
  }
  #navOutter .navBar #navCont .rightLinks .mobile:hover img {
    filter: brightness(0.5);
  }
  #navOutter .navBar #navCont .rightLinks .active img {
    filter: brightness(0.1);
  }
}/*# sourceMappingURL=NavStyle.css.map */