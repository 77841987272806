@import '../../variables';
.services{
    color: $col1;
    // background-color: $col2;
    background-image: linear-gradient(172deg, $col2 0%, $col22 50%);
    text-align: center;
    section{
        h2{
            padding-top:120px;
        }
        h5{
            color:$col8;
            padding: 38px 0 134px 0;
            font-size:0.93em; font-weight: 400; font-family: $font2;
        }
    }
    article{
        width: 90vw; margin:auto;
        display: grid;
        grid-template-columns: repeat(2, 45%);
        grid-gap: 36px 36px; 
        justify-content: center;
        padding:12px 0 96px 0;
    }
    hr{
        position:relative;
        bottom: 32px; width:60%;
        margin:auto;
        color: $col4;
    }
}
@media (max-width : $xxlBreakPoint) {
    .services{
        article{
            width:95vw;
            grid-template-columns: repeat(2,48%);
        }
    }
}
@media (max-width : $lgBreakPoint) {
    .services{
        article{
            width:100vw;
            justify-content: space-around;
            grid-template-columns: repeat(1,90%);
            grid-gap:36px 4px;
        }
        section{
            h5{
                margin-inline:4px;
            }
        }
    }
}